/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mix-dev@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssetInput } from '../models';
// @ts-ignore
import { AssetListOutput } from '../models';
// @ts-ignore
import { AssetOutput } from '../models';
// @ts-ignore
import { SimpleError } from '../models';
/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new asset to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {AssetInput} assetInput Asset definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAsset: async (stage: 'LIVE' | 'SANDBOX', assetInput: AssetInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('addAsset', 'stage', stage)
            // verify required parameter 'assetInput' is not null or undefined
            assertParamExists('addAsset', 'assetInput', assetInput)
            const localVarPath = `/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an asset in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset: async (stage: 'LIVE' | 'SANDBOX', assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('deleteAsset', 'stage', stage)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteAsset', 'assetId', assetId)
            const localVarPath = `/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve an asset from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {boolean} [withPresignedUrl] If \&#39;true\&#39; the asset returned will include a presigned URL for the S3 resources associated with this asset
         * @param {boolean} [withCloudFrontUrl] If \&#39;true\&#39; the asset returned will include a cloudfront URL for S3 resources associated with this asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (stage: 'LIVE' | 'SANDBOX', assetId: string, withPresignedUrl?: boolean, withCloudFrontUrl?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('getAsset', 'stage', stage)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAsset', 'assetId', assetId)
            const localVarPath = `/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (withPresignedUrl !== undefined) {
                localVarQueryParameter['withPresignedUrl'] = withPresignedUrl;
            }

            if (withCloudFrontUrl !== undefined) {
                localVarQueryParameter['withCloudFrontUrl'] = withCloudFrontUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates assets in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {'AUDIO' | 'IMAGE' | 'TTS'} [type] The type of assets to retrieve
         * @param {'DEFAULT_ARTIST_IMAGE' | 'SPOTLIGHT' | 'NEW_RELEASE_ANNOUNCEMENT' | 'VOICE_COMMENT' | 'VOICE_UPSELL_AUDIO_NEW_RELEASE' | 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG'} [category] The category of assets to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets: async (stage: 'LIVE' | 'SANDBOX', maxResults?: number, nextToken?: string, type?: 'AUDIO' | 'IMAGE' | 'TTS', category?: 'DEFAULT_ARTIST_IMAGE' | 'SPOTLIGHT' | 'NEW_RELEASE_ANNOUNCEMENT' | 'VOICE_COMMENT' | 'VOICE_UPSELL_AUDIO_NEW_RELEASE' | 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('listAssets', 'stage', stage)
            const localVarPath = `/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing asset in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {AssetInput} assetInput Asset definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset: async (stage: 'LIVE' | 'SANDBOX', assetId: string, assetInput: AssetInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stage' is not null or undefined
            assertParamExists('updateAsset', 'stage', stage)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateAsset', 'assetId', assetId)
            // verify required parameter 'assetInput' is not null or undefined
            assertParamExists('updateAsset', 'assetInput', assetInput)
            const localVarPath = `/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new asset to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {AssetInput} assetInput Asset definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAsset(stage: 'LIVE' | 'SANDBOX', assetInput: AssetInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAsset(stage, assetInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an asset in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAsset(stage, assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve an asset from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {boolean} [withPresignedUrl] If \&#39;true\&#39; the asset returned will include a presigned URL for the S3 resources associated with this asset
         * @param {boolean} [withCloudFrontUrl] If \&#39;true\&#39; the asset returned will include a cloudfront URL for S3 resources associated with this asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, withPresignedUrl?: boolean, withCloudFrontUrl?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(stage, assetId, withPresignedUrl, withCloudFrontUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enumerates assets in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {'AUDIO' | 'IMAGE' | 'TTS'} [type] The type of assets to retrieve
         * @param {'DEFAULT_ARTIST_IMAGE' | 'SPOTLIGHT' | 'NEW_RELEASE_ANNOUNCEMENT' | 'VOICE_COMMENT' | 'VOICE_UPSELL_AUDIO_NEW_RELEASE' | 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG'} [category] The category of assets to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssets(stage: 'LIVE' | 'SANDBOX', maxResults?: number, nextToken?: string, type?: 'AUDIO' | 'IMAGE' | 'TTS', category?: 'DEFAULT_ARTIST_IMAGE' | 'SPOTLIGHT' | 'NEW_RELEASE_ANNOUNCEMENT' | 'VOICE_COMMENT' | 'VOICE_UPSELL_AUDIO_NEW_RELEASE' | 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssets(stage, maxResults, nextToken, type, category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing asset in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {AssetInput} assetInput Asset definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, assetInput: AssetInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAsset(stage, assetId, assetInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new asset to the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {AssetInput} assetInput Asset definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAsset(stage: 'LIVE' | 'SANDBOX', assetInput: AssetInput, options?: any): AxiosPromise<AssetOutput> {
            return localVarFp.addAsset(stage, assetInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an asset in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAsset(stage, assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve an asset from the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {boolean} [withPresignedUrl] If \&#39;true\&#39; the asset returned will include a presigned URL for the S3 resources associated with this asset
         * @param {boolean} [withCloudFrontUrl] If \&#39;true\&#39; the asset returned will include a cloudfront URL for S3 resources associated with this asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, withPresignedUrl?: boolean, withCloudFrontUrl?: boolean, options?: any): AxiosPromise<AssetOutput> {
            return localVarFp.getAsset(stage, assetId, withPresignedUrl, withCloudFrontUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates assets in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {number} [maxResults] The maximum number of results to return for paginated queries
         * @param {string} [nextToken] The next page token to retrieve from a paginated query
         * @param {'AUDIO' | 'IMAGE' | 'TTS'} [type] The type of assets to retrieve
         * @param {'DEFAULT_ARTIST_IMAGE' | 'SPOTLIGHT' | 'NEW_RELEASE_ANNOUNCEMENT' | 'VOICE_COMMENT' | 'VOICE_UPSELL_AUDIO_NEW_RELEASE' | 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG'} [category] The category of assets to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets(stage: 'LIVE' | 'SANDBOX', maxResults?: number, nextToken?: string, type?: 'AUDIO' | 'IMAGE' | 'TTS', category?: 'DEFAULT_ARTIST_IMAGE' | 'SPOTLIGHT' | 'NEW_RELEASE_ANNOUNCEMENT' | 'VOICE_COMMENT' | 'VOICE_UPSELL_AUDIO_NEW_RELEASE' | 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG', options?: any): AxiosPromise<AssetListOutput> {
            return localVarFp.listAssets(stage, maxResults, nextToken, type, category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing asset in the catalog
         * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
         * @param {string} assetId The ID of the asset to update or delete
         * @param {AssetInput} assetInput Asset definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, assetInput: AssetInput, options?: any): AxiosPromise<AssetOutput> {
            return localVarFp.updateAsset(stage, assetId, assetInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * 
     * @summary Add a new asset to the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {AssetInput} assetInput Asset definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public addAsset(stage: 'LIVE' | 'SANDBOX', assetInput: AssetInput, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).addAsset(stage, assetInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an asset in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} assetId The ID of the asset to update or delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public deleteAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).deleteAsset(stage, assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve an asset from the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} assetId The ID of the asset to update or delete
     * @param {boolean} [withPresignedUrl] If \&#39;true\&#39; the asset returned will include a presigned URL for the S3 resources associated with this asset
     * @param {boolean} [withCloudFrontUrl] If \&#39;true\&#39; the asset returned will include a cloudfront URL for S3 resources associated with this asset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, withPresignedUrl?: boolean, withCloudFrontUrl?: boolean, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).getAsset(stage, assetId, withPresignedUrl, withCloudFrontUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates assets in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {number} [maxResults] The maximum number of results to return for paginated queries
     * @param {string} [nextToken] The next page token to retrieve from a paginated query
     * @param {'AUDIO' | 'IMAGE' | 'TTS'} [type] The type of assets to retrieve
     * @param {'DEFAULT_ARTIST_IMAGE' | 'SPOTLIGHT' | 'NEW_RELEASE_ANNOUNCEMENT' | 'VOICE_COMMENT' | 'VOICE_UPSELL_AUDIO_NEW_RELEASE' | 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG'} [category] The category of assets to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public listAssets(stage: 'LIVE' | 'SANDBOX', maxResults?: number, nextToken?: string, type?: 'AUDIO' | 'IMAGE' | 'TTS', category?: 'DEFAULT_ARTIST_IMAGE' | 'SPOTLIGHT' | 'NEW_RELEASE_ANNOUNCEMENT' | 'VOICE_COMMENT' | 'VOICE_UPSELL_AUDIO_NEW_RELEASE' | 'VOICE_UPSELL_AUDIO_ENTIRE_CATALOG', options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).listAssets(stage, maxResults, nextToken, type, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing asset in the catalog
     * @param {'LIVE' | 'SANDBOX'} stage The stage in the catalog where the operation should take place
     * @param {string} assetId The ID of the asset to update or delete
     * @param {AssetInput} assetInput Asset definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public updateAsset(stage: 'LIVE' | 'SANDBOX', assetId: string, assetInput: AssetInput, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).updateAsset(stage, assetId, assetInput, options).then((request) => request(this.axios, this.basePath));
    }
}
