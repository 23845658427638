/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Manager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mix-dev@amazon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum AssetType {
    AUDIO = 'AUDIO',
    IMAGE = 'IMAGE',
    TTS = 'TTS'
}


